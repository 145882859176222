import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/pages/login/login.component';
import { InitiateLoginComponent } from './components/util/login/initiate-login/initiate-login.component';
import { FooterComponent } from './components/util/footer/footer/footer.component';
import { HeaderComponent } from './components/util/header/header/header.component';
import { InitiateAuthenticationComponent } from './components/util/login/initiate-authentication/initiate-authentication.component';
import { AuthenticateComponent } from './components/util/login/authenticate/authenticate.component';
import { ClassicLoginProviderComponent } from './components/util/login/classic-login-provider/classic-login-provider.component';
import { ButtonComponent } from './components/util/form/button/button.component';
import { InputGroupComponent } from './components/util/form/input-group/input-group.component';
import { LogoComponent } from './components/util/logo/logo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { RegexUtil } from './helpers/regex.helper';
import { Fido2Helper } from './helpers/fido2.helper';
import { SharedDataService } from './../service/shared-data.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { LandingPageComponent } from './components/util/dashboard/landing-page/landing-page.component';
import { EditProfileComponent } from './components/util/dashboard/edit-profile/edit-profile.component';
import { AccessDetailsComponent } from './components/pages/access-details/access-details.component';
import { EditDetailsComponent } from './components/util/dashboard/edit-details/edit-details.component';
import { AdditionalInfoComponent } from './components/util/register/additional-info/additional-info.component';
import { MfaInfoComponent } from './components/util/register/mfa-info/mfa-info.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { DevicesComponent } from './components/pages/devices/devices.component';
import { DevicesDetailsComponent } from './components/util/dashboard/devices-details/devices-details.component';
import { ConsentComponent } from './components/pages/consent/consent.component';
import { ConsentDetailsComponent } from './components/util/dashboard/consent-details/consent-details.component';
import { CompleteRegisterComponent } from './components/util/complete-register/complete-register/complete-register.component';
import { FirstStepComponent } from './components/util/complete-register/pages/first-step/first-step.component';
import { SecondPageComponent } from './components/util/complete-register/pages/second-page/second-page.component';
import { ThirdStepComponent } from './components/util/complete-register/pages/third-step/third-step.component';
import { FourthStepComponent } from './components/util/complete-register/pages/fourth-step/fourth-step.component';
import { TranslateNavigationComponent } from './components/util/translate-nav/translate-navigation/translate-navigation.component';
import { UserAccountComponent } from './components/pages/user-account/user-account.component';
import { ManageLoginComponent } from './components/pages/manage-login/manage-login.component';
import { RegisterWithoutVideoIdentComponent } from './components/util/complete-register/pages/third-step/register-without-video-ident/register-without-video-ident.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ManualPictureUploadComponent } from './components/util/complete-register/pages/third-step/manual-picture-upload/manual-picture-upload.component';
import { InfoVideoIdentComponent } from './components/util/complete-register/pages/third-step/info-video-ident/info-video-ident.component';
import { ForgotPasswordInitComponent } from './components/pages/forgot-password-init/forgot-password-init.component';
import { CustomValidatorHelper } from './helpers/custom-validator.helper';
import { PasswordSetSuccessComponent } from './components/pages/password-set-success/password-set-success.component';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { CidaasService } from '../service/cidaas.service';
import { IdValidatorService } from '../service/idvalidator.service';
import { CompleteRegisterService } from '../service/complete-register.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileUploadComponent } from './components/util/complete-register/pages/profile-upload/profile-upload.component';
import { PasswordSetComponent } from './components/pages/password-set/password-set.component';
import { LayoutComponent } from './layout/layout.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CampusCardComponent } from './components/pages/profile/campus-card/campus-card.component';
import { LoaderComponent } from './components/util/loader/loader.component';
import { SpinnerInterceptor } from 'src/service/spinner.interceptor';
import { VideoPreviewComponent } from './components/util/complete-register/pages/video-preview/video-preview.component';
import { NumberFormatPipe } from './helpers/number-format.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortOptionsPipe } from './helpers/sort-options.pipe';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  var currentDate = new Date();
  var today = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  var version = today.getTime();
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + version + "v1");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InitiateLoginComponent,
    FooterComponent,
    HeaderComponent,
    InitiateAuthenticationComponent,
    AuthenticateComponent,
    ClassicLoginProviderComponent,
    ButtonComponent,
    InputGroupComponent,
    LogoComponent,
    ProfileComponent,
    DashboardComponent,
    LandingPageComponent,
    EditProfileComponent,
    AccessDetailsComponent,
    EditDetailsComponent,
    MfaInfoComponent,
    RegisterComponent,
    LogoutComponent,
    DevicesComponent,
    DevicesDetailsComponent,
    ConsentComponent,
    ConsentDetailsComponent,
    CompleteRegisterComponent,
    FirstStepComponent,
    SecondPageComponent,
    ThirdStepComponent,
    FourthStepComponent,
    RegisterWithoutVideoIdentComponent,
    ManualPictureUploadComponent,
    InfoVideoIdentComponent,
    AdditionalInfoComponent,
    TranslateNavigationComponent,
    UserAccountComponent,
    ManageLoginComponent,
    ForgotPasswordInitComponent,
    PasswordSetComponent,
    PasswordSetSuccessComponent,
    ProfileUploadComponent,
    LayoutComponent,
    CampusCardComponent,
    LoaderComponent,
    VideoPreviewComponent,
    NumberFormatPipe,
    SortOptionsPipe
  ],
  imports: [
    ImageCropperModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BsDatepickerModule.forRoot(),
    DynamicFormModule
  ],
  providers: [RegexUtil, Fido2Helper, CidaasService, SharedDataService, CompleteRegisterService, CustomValidatorHelper, IdValidatorService, NumberFormatPipe,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
